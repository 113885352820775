<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 밝음 급여내역</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <label>기간검색 :
                            <select name="birth-yyyy" v-model="startYY" class="ml-20" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="startMM" class="ml-10" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            ~
                            <select name="birth-yyyy" v-model="endYY" class="ml-10" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="endMM" class="ml-10" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            <a class="btn_search pointer" @click="getCenterPayList()">Search<span></span></a>
                        </label>
                    </div>
                    <!-- <div class="con_top mt-10">
                        <h3 class="bold">급여 현황</h3>
                        <table class="default_table mt-10" style="max-width:350px;">
                            <tr>
                                <th>정산유형</th>
                                <th>총계</th>
                                <th>실입금액</th>
                            </tr>
                            <tr>
                                <td>과세</td>
                                <td>000,000</td>
                                <td>000,000</td>
                            </tr>
                        </table>
                    </div> -->
                    <div class="con_teop mt-10">
                        <table class="default_table mt-10">
                            <tbody v-if="loading">
                                <tr>
                                    <th>상담일자</th>
                                    <th>상담사 명</th>
                                    <th>고객 명</th>
                                    <th>기업 명</th>
                                    <th>상담료</th>
                                </tr>
                                <tr>
                                    <td colspan="5">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>상담일자</th>
                                    <th>상담사 명</th>
                                    <th>고객 명</th>
                                    <th>기업 명</th>
                                    <th>상담료</th>
                                </tr>
                                <tr v-for="(item,index) in payList" :key="index">
                                    <td>{{item.consDate}}</td>
                                    <td>{{item.staffName}}</td>
                                    <td>{{item.clientName}}</td>
                                    <td>{{item.companyName}}</td>
                                    <td>{{item.consPrice}}</td>
                                </tr>
                                <tr v-if="payList.length === 0">
                                    <td colspan="5">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        startYY: 2020,
        startMM: 1,
        endYY: 2020,
        endMM: 1,

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)

        payList: [],
        loading:true,

    }),

    mounted() {

        let date = new Date()
        this.startYY = date.getFullYear()
        this.startMM = date.getMonth() + 1
        this.endYY = date.getFullYear()
        this.endMM = date.getMonth() + 1

        this.getCenterPayList();

    },

    methods: {

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

        getCenterPayList() {
            let tempMM = ""
            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                tempMM = "0" + this.startMM
            } else {
                tempMM = this.startMM
            }
            let startDate = this.startYY.toString() + '-' + tempMM

            if (this.endMM < 10) {
                tempMM = "0" + this.endMM
            } else {
                tempMM = this.endMM
            }
            let endDate = this.endYY.toString() + '-' + tempMM

            let params= {
                idxCrmCenter: this.idxCrmCenter
                , startDate: startDate
                , endDate: endDate
                , pageSize: this.pageSize
                , pageNum: this.pageNum
            };

            
            this.loading = true;

            this.axios.get('/api/v2/pay/center/list', {
                params: params
            })
                .then(res => {
                    if (res.data.err === 0) {
                        this.payList = res.data.payList

                        if (res.data.payListSize) {
                            this.listLength = res.data.payListSize
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)

                    } else {
                        this.payList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                }).finally(()=>{
                    this.loading = false;
                });

        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getCenterPayList()
            }
        },

        paging(index) {
            this.pageNum = index
            this.getCenterPayList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getCenterPayList()
            }
        },

    }
}
</script>
